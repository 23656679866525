
  .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 36rem;
    margin: 0 auto 10rem;
    padding: 3rem;
    height: 25rem;
    text-align: center;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2), 0 .5rem .5rem 0 rgba(0, 0, 0, 0.24);
  }
  .form h1 {
    color: #08522d;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1rem;
  }
  .form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 1.5rem;
    padding: 1rem;
    box-sizing: border-box;
    font-size: 1.4rem;
  }
  .form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #4CAF50;
    width: 100%;
    border: 0;
    padding: 1.2rem;
    color: #FFFFFF;
    font-size: 1.4rem;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  .form button:hover,.form button:active,.form button:focus {
    background: #43A047;
  }
  .form button:disabled {
    background-color: #c0e7de;
    cursor: not-allowed;
  }
  .form .message {
    margin: 1.5rem 0 0;
    color: #b3b3b3;
    font-size: 1.2rem;
  }
  .form .message a {
    color: #4CAF50;
    text-decoration: none;
  }
  .form .register-form {
    display: none;
  }
  .container {
    position: relative;
    z-index: 1;
    max-width: 30rem;
    margin: 0 auto;
  }
  .container:before, .container:after {
    content: "";
    display: block;
    clear: both;
  }
  .container .info {
    margin: 5rem auto;
    text-align: center;
  }
  .container .info h1 {
    margin: 0 0 1.5rem;
    padding: 0;
    font-size: 3.6rem;
    font-weight: 300;
    color: #1a1a1a;
  }
  .container .info span {
    color: #4d4d4d;
    font-size: 1.2rem;
  }
  .container .info span a {
    color: #000000;
    text-decoration: none;
  }
  .container .info span .fa {
    color: #EF3B3A;
  }

@media (max-width : 700px) {
    .form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 15;
    }
}