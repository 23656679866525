.chats {
    display: grid;
    grid-template-rows: 5rem 40rem 5rem;
}

.roomName-container {
    background-color: #edeff1;
    color: #222;
    padding: 1.3rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.room-name {
    font-size: 1.8rem;
    cursor: pointer;
}

.message-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.message-holder {
    display: flex;
    justify-content: center;
    align-items: center;

    &--admin {
        align-self: flex-end;
        flex-direction: row-reverse;
    }

    &--others {
        align-self: flex-start;
    }
}

.input-box {
    background-color: #edeff1;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.formee {
    width: 90%;
}

.input {
    width: 100%;
    padding: 1rem;
    border-radius: .8rem;
    border: none;
    outline: none;
}

.emoji {
    font-size: 3rem !important;
    margin-right: 1rem;
    cursor: pointer !important;
    color: #222;
}

.themes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.theme {
    cursor: pointer;
    
    &:not(:last-child) {
        margin-right: 1rem;
    }
}