.u-input-group {
    margin: 1rem 0;
}

.u-paper {
    width: 40rem;
    max-width: 50rem;
    height: 40rem;
    margin: 0 auto;
    padding: 3rem;
}

.u-input {
    display: block !important;
    margin-bottom: 1rem !important;
}

.u-btn {
    display: block !important; 
}