.join {
    height: 100vh;
    width: 100%;
    display: flex;

    &__avatars {
        width: 50vw;
        height: 100%;
        display: grid;
        grid-template-rows: repeat(auto-fit, minmax(10rem, 1fr));
    }

    &__form {
        width: 50vw;
        height: 100%;
        background-color: #edf2f7;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.palettes {
    display: flex;
    max-height: 100%;

    &--1 {
        background-color: #D4C6F2;
    }

    &--2 {
        background-color: #59BAEF;
    }

    &--3 {
        background-color: #527DD9;
    }

    &--4 {
        background-color: #7953DC;
    }

    &--5 {
        background-color: #9B35B3;
    }
}

.avatar {
    height: 200px;
    max-height: 100%;
}

@media (max-width : 700px) {
    .join {
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .join__avatars {
        width: 100%;
        height: 50vh;
        position: relative;
        z-index: 10;
    }

    .join__form {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}